import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CarouselContext } from 'pure-react-carousel';

import styles from './SlideCarouselLogo.module.scss';
import { usePrevious } from '../utils/hooks';

function SlideCarouselLogo() {
  const data = useStaticQuery(graphql`
    {
      sanitySite {
        logos {
          verticalWhiteImage {
            asset {
              url
            }
          }
          verticalColorImage {
            asset {
              url
            }
          }
        }
        homeSection {
          slides {
            coloredLogo
          }
        }
      }
    }
  `);

  const {
    logos,
    homeSection: { slides },
  } = data.sanitySite;

  const carouselContext = useContext(CarouselContext);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(carouselContext.state.currentSlide);

  useEffect(() => {
    function onChange() {
      setCurrentSlideIndex(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const { coloredLogo } = slides[currentSlideIndex];
  return (
    <div className={styles.container}>
      <img
        className={styles.logo + (!coloredLogo ? ' ' + styles.hidden : '')}
        src={logos.verticalColorImage.asset.url}
        alt="background"
      ></img>
      <img
        className={styles.logo + (coloredLogo ? ' ' + styles.hidden : '')}
        src={logos.verticalWhiteImage.asset.url}
        alt="background"
      ></img>
    </div>
  );
}

export default SlideCarouselLogo;
