import React from 'react';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';
import { SanityImage } from '../types';

interface PartnersProps {
  watermark: string;
  sectionTitle: React.ReactNode;
  partners: Array<{
    profileImage: SanityImage;
    name: string;
    department: string;
    linkedInUrl: string;
    description: React.ReactNode;
  }>;
  lang: string;
}

const Partners = ({ watermark, sectionTitle, partners, lang }: PartnersProps) => {
  return (
    <section id="partners" className="section">
      <div id="partners-anchor" className="anchor"></div>
      <div className="container">
        <header className="section-header">
          <div className="watermarkContainer">
            <strong className="fade-title">{watermark}</strong>
          </div>
          <h2 className="section-title">{sectionTitle}</h2>
        </header>
      </div>
      <div className="container partners-members-container">
        <div className="content">
          <div className="partners-workers">
            {partners.map((partner, i) => (
              <div key={i} className="partners-contact wow fadeInUp">
                <img
                  className="profile-image"
                  src={partner.profileImage.asset.url + '?w=260&h=260&fit=max'}
                  alt={
                    lang === 'pt'
                      ? 'Foto de Perfil de ' + partner.name
                      : partner.name + "'s Profile Picture"
                  }
                />
                <h3 className="partner-name">{partner.name}</h3>
                <span className="partner-department">{partner.department}</span>
                {partner.linkedInUrl ? (
                  <a
                    aria-label="Link to LinkedIn"
                    target="_blank"
                    rel="noreferrer"
                    href={partner.linkedInUrl}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                ) : null}
                <p className="partner-description">{partner.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
