import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import BlockContent from '@sanity/block-content-to-react';
import SimpleReactLightbox from 'simple-react-lightbox';
import objectFitImages from 'object-fit-images';
import '../translation/i18n';

import serializers from '../utils/serializers';

// import Watermark from '../assets/Elementos-SITE-CleverREAL-05.png';

import SEO from '../components/seo';
import Menu from '../components/Menu';
import SlideCarousel from '../components/SlideCarousel';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import Partners from '../components/Partners';
import TrackRecord from '../components/TrackRecord';
import Contacts from '../components/Contacts';
import Footer from '../components/Footer';

// import variables from '../styles/variables.scss';
import './index.scss';
import './tablet.scss';
import './mobile.scss';

import '../fragments.ts';
import { renderTitle } from '../utils/functions';
import { DEFAULT_LANG } from '../constants';
import { localize } from '../utils/functions';
import Helmet from 'react-helmet';

interface IndexPageProps {
  lang?: string;
}

const IndexPage = ({ lang = DEFAULT_LANG }: IndexPageProps) => {
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    __main(jQuery);
    objectFitImages();
  }, []);
  const data = useStaticQuery(graphql`
    {
      sanitySite {
        logos {
          horizontalImage {
            asset {
              url
            }
          }
          horizontalImageTextOnly {
            asset {
              url
            }
          }
        }
        menu {
          home {
            ...LocaleStringFragment
          }
          aboutUs {
            ...LocaleStringFragment
          }
          ourServices {
            ...LocaleStringFragment
          }
          partners {
            ...LocaleStringFragment
          }
          trackRecord {
            ...LocaleStringFragment
          }
          contacts {
            ...LocaleStringFragment
          }
        }
        homeSection {
          slides {
            text {
              ...LocaleTextFragment
            }
            background {
              asset {
                url
              }
            }
          }
        }
        aboutUsSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          introText {
            ...LocaleBasicPortableTextFragment
          }
          missionTitle {
            ...LocaleStringFragment
          }
          missionText {
            ...LocaleTextFragment
          }
          valuesTitle {
            ...LocaleStringFragment
          }
          valuesText {
            ...LocaleTextFragment
          }
        }
        _rawAboutUsSection
        ourServicesSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          intro {
            ...LocaleBasicPortableTextFragment
          }
          servicesTitle {
            ...LocaleStringFragment
          }
          services {
            image {
              asset {
                url
              }
            }
            name {
              ...LocaleStringFragment
            }
            description {
              ...LocaleTextFragment
            }
          }
        }
        _rawOurServicesSection
        partnersSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          partnersWorkers {
            profileImage {
              asset {
                url
              }
            }
            name
            department {
              ...LocaleStringFragment
            }
            linkedInUrl
            description {
              ...LocaleTextFragment
            }
          }
        }
        # FALTA O TRACK RECORD
        trackRecordSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          intro {
            ...LocaleBasicPortableTextFragment
          }
          projects {
            _key
            image {
              asset {
                url
              }
            }
            projectName {
              ...LocaleStringFragment
            }
            projectDate {
              ...LocaleStringFragment
            }
            description {
              ...LocaleTextFragment
            }
          }
          investment {
            ...LocaleStringFragment
          }
          buildings {
            ...LocaleStringFragment
          }
          irrPercentage {
            ...LocaleStringFragment
          }
        }
        _rawTrackRecordSection
        contactsSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          callToAction {
            ...LocaleStringFragment
          }
          address
          phone
          telephone
          email
        }
      }
    }
  `);

  const { i18n } = useTranslation();
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }
  const {
    logos,
    homeSection,
    menu,
    aboutUsSection,
    ourServicesSection,
    partnersSection,
    trackRecordSection,
    contactsSection,
  } = localize(data.sanitySite, langs);

  const navigationBar = [
    { anchor: 'home', name: menu.home },
    { anchor: 'aboutUs', name: menu.aboutUs },
    { anchor: 'ourServices', name: menu.ourServices },
    { anchor: 'partners', name: menu.partners },
    { anchor: 'trackRecord', name: menu.trackRecord },
    { anchor: 'contacts', name: menu.contacts },
  ];
  return (
    <SimpleReactLightbox>
      <Helmet htmlAttributes={{ lang: i18n.language }}></Helmet>
      <div>
        <SEO lang={lang} />
        <header id="top" className="header-home">
          <Menu
            horizontalLogo={logos.horizontalImageTextOnly.asset.url}
            navigationBar={navigationBar}
            lang={lang}
          ></Menu>
        </header>
        <div className="layout" id="home-anchor">
          <SlideCarousel
            slides={homeSection.slides.map((slide: any) => ({
              background: slide.background.asset.url + '?w=1920&h=1080&fit=min',
              text: slide.text,
            }))}
            lang={lang}
          ></SlideCarousel>
          <AboutUs
            watermark={aboutUsSection.sectionTitle}
            sectionTitle={renderTitle(aboutUsSection.sectionTitle)}
            textIntro={
              <BlockContent
                blocks={aboutUsSection.introText}
                serializers={serializers}
              ></BlockContent>
            }
            missionTitle={aboutUsSection.missionTitle}
            missionText={aboutUsSection.missionText}
            valuesTitle={aboutUsSection.valuesTitle}
            values={aboutUsSection.valuesText}
          ></AboutUs>
          <Services
            watermark={ourServicesSection.sectionTitle}
            sectionTitle={renderTitle(ourServicesSection.sectionTitle)}
            introText={
              <BlockContent
                blocks={ourServicesSection.intro}
                serializers={serializers}
              ></BlockContent>
            }
            servicesTitle={ourServicesSection.servicesTitle}
            services={ourServicesSection.services}
          ></Services>
          <Partners
            watermark={partnersSection.sectionTitle}
            sectionTitle={renderTitle(partnersSection.sectionTitle)}
            partners={partnersSection.partnersWorkers}
            lang={lang}
          ></Partners>
          <TrackRecord
            lang={lang}
            watermark={trackRecordSection.sectionTitle}
            sectionTitle={renderTitle(trackRecordSection.sectionTitle)}
            introText={
              <BlockContent
                blocks={ourServicesSection.intro}
                serializers={serializers}
              ></BlockContent>
            }
            cardsCarrousel={trackRecordSection.projects}
            investment={trackRecordSection.investment}
            buildings={trackRecordSection.buildings}
            irrPercentage={trackRecordSection.irrPercentage}
          ></TrackRecord>
          <Contacts
            watermark={contactsSection.sectionTitle}
            sectionTitle={renderTitle(contactsSection.sectionTitle)}
            callToAction={contactsSection.callToAction}
            address={contactsSection.address}
            contactPhone={contactsSection.phone}
            contactTelephone={contactsSection.telephone}
            contactEmail={contactsSection.email}
          ></Contacts>
          <Footer lang={lang} horizontalLogo={logos.horizontalImage.asset.url}></Footer>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default IndexPage;
