import React from 'react';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';

interface FooterProps {
  horizontalLogo: string;
  lang: string;
}

const Footer = ({ horizontalLogo, lang }: FooterProps) => {
  return (
    <div className="content">
      {/* Footer */}
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row-base row">
            <div className="col-base text-left-md col-md-6">
              <img className="footer-logo" src={horizontalLogo} alt=" Terra Development Logo" />
            </div>
            <div className="col-base text-right-md col-md-6">
              &copy; 2020 | All Rights Reserved | Powered by{' '}
              {lang === 'pt' ? (
                <a href="https://moscadigital.pt/" rel="noreferrer" target="_blank">
                  Mosca Digital
                </a>
              ) : (
                <a href="https://moscadigital.pt/en/" rel="noreferrer" target="_blank">
                  Mosca Digital
                </a>
              )}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
