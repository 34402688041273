import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useWindowSize } from '../utils/hooks';

import styles from './CardCarousel.module.scss';

interface CardCarouselProps {
  cards: Array<{
    imageSrc: string;
    title: string;
    text: string;
  }>;
}

const responsive = {
  // the name can be any, depends on you.
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    partialVisibilityGutter: 15,
  },
};

const ButtonGroup = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
}: {
  next: () => void;
  previous: () => void;
  carouselState: { currentSlide: number; totalItems: number; slidesToShow: number };
}) => {
  return (
    // remember to give it position:absolute
    <div className={styles.buttonGroup}>
      <button
        aria-label="Button Left"
        disabled={currentSlide === 0}
        className={styles.button + ' ' + styles.buttonLeft}
        onClick={() => previous()}
      >
        <i className={styles.buttonIcon + ' fas fa-chevron-left'}></i>
      </button>

      <button
        aria-label="Button Right"
        disabled={currentSlide === totalItems - slidesToShow}
        className={styles.button + ' ' + styles.buttonRight}
        onClick={() => next()}
      >
        <i className={styles.buttonIcon + ' fas fa-chevron-right'}></i>
      </button>
    </div>
  );
};

const CardCarousel = ({ cards }: CardCarouselProps): React.ReactElement => {
  const { width } = useWindowSize();
  return (
    <div className={styles.container}>
      <Carousel
        arrows={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        containerClass={styles.carousel}
        // @ts-ignore
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside
        infinite
        autoPlay={!!width && width > 768}
      >
        {cards.map((slide, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.overlay}></div>
            <img
              src={slide.imageSrc + '?w=960&fit=max'}
              className={styles.image}
              alt={slide.title}
            ></img>
            <div className={styles.cardContainer}>
              <h3 className={styles.title}>{slide.title}</h3>
              <p className={styles.text}>{slide.text} </p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CardCarousel;
