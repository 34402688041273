import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { useTranslation } from 'react-i18next';
import '../translation/i18n';
import '../fragments.ts';
import { DEFAULT_LANG } from '../constants';
import { localize } from '../utils/functions';

interface SEOProps {
  lang?: string;
  meta?: Array<
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  >;
}

function SEO({ lang = 'pt', meta = [] }: SEOProps) {
  const data = useStaticQuery(
    graphql`
      {
        sanityMetadata {
          title {
            ...LocaleStringFragment
          }
          description {
            ...LocaleStringFragment
          }
          url {
            ...LocaleUrlFragment
          }
          image {
            ...LocaleImageFragment
          }
        }
      }
    `,
  );

  const { i18n } = useTranslation();
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }
  const metadata = localize(data.sanityMetadata, langs);

  const metaList = [
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
  ].concat(meta);

  if (metadata.title) {
    metaList.push(
      ...[
        {
          property: 'og:title',
          content: metadata.title,
        },
        {
          name: 'twitter:title',
          content: metadata.title,
        },
      ],
    );
  }

  if (metadata.description) {
    metaList.push(
      ...[
        {
          name: 'description',
          content: metadata.description,
        },
        {
          property: 'og:description',
          content: metadata.description,
        },
        {
          name: 'twitter:description',
          content: metadata.description,
        },
      ],
    );
  }

  if (metadata.url) {
    metaList.push({
      property: 'og:url',
      content: metadata.url,
    });
  }

  if (metadata.image) {
    metaList.push({
      property: 'og:image',
      content: metadata.image.asset.url,
    });
  }

  metadata.author = 'Mosca Digital';
  if (metadata.author) {
    metaList.push(
      ...[
        {
          name: 'author',
          content: metadata.author,
        },
        {
          name: 'twitter:creator',
          content: metadata.author,
        },
      ],
    );
  }

  // if (metadata.facebook_app_id) {
  //   metaList.push({
  //     property: 'fb:app_id',
  //     content: metadata.facebook_app_id,
  //   });
  // }

  return <Helmet title={metadata.title} meta={metaList} />;
}

export default SEO;
