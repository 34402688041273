import React from 'react';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';
import { SanityImage } from '../types';

interface ServicesProps {
  watermark: string;
  sectionTitle: React.ReactNode;
  introText: React.ReactNode;
  servicesTitle: string;
  services: Array<{
    name: string;
    description: string;
    image: SanityImage;
  }>;
}

const Services = ({
  watermark,
  sectionTitle,
  introText,
  servicesTitle,
  services,
}: ServicesProps) => {
  return (
    <section id="ourServices" className="section">
      <div id="ourServices-anchor" className="anchor"></div>
      <div className="container">
        <header className="section-header">
          <div className="watermarkContainer">
            <strong className="fade-title">{watermark}</strong>
          </div>
          <h2 className="section-title">{sectionTitle}</h2>
        </header>
        <div className="section-content wow fadeIn text-intro-container">{introText}</div>
        <div className="content">
          <h3 className="wow fadeInUp serviceTitle">{servicesTitle}</h3>
          <div className="services">
            {services.map((service, i) => (
              <div key={i} className="service wow fadeInUp">
                <img
                  className="serviceIcon"
                  src={service.image.asset.url + '?w=140&h=100&fit=max'}
                  alt={service.name}
                />
                <h3 className="subtitle services-title">{service.name}</h3>
                <p className="services-description">{service.description}</p>
              </div>
            ))}
            {Array.from({ length: (3 - (services.length % 3)) % 3 }).map((__, i: number) => (
              <div key={i} className="mockService"></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
