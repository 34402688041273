import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import SlideCarouselLogo from './SlideCarouselLogo';

import { useWindowSize } from '../utils/hooks';

import styles from './SlideCarousel.module.scss';

interface CarouselProps {
  slides: Array<{
    background: string;
    text: string;
  }>;
  lang: string;
}

const SlideCarousel = ({ slides, lang }: CarouselProps): React.ReactElement => {
  const { width } = useWindowSize();

  return (
    <CarouselProvider
      className={styles.carousel}
      infinite
      naturalSlideWidth={!width || width >= 1200 ? 2 : 1.2}
      naturalSlideHeight={1}
      totalSlides={slides.length}
      isPlaying
      interval={10000}
    >
      <div className="brand-panel">
        <SlideCarouselLogo />
      </div>
      <Slider className={styles.slider}>
        {slides.map((slide, i) =>
          slide.text ? (
            <Slide key={i} className={styles.slide} index={i}>
              <Image
                className={styles.image}
                hasMasterSpinner
                src={slide.background}
                alt=""
              ></Image>
              <div
                className={styles.bottomBar + (slides.length === 1 ? ' ' + styles.noDotGroup : '')}
              >
                <h2 className={styles.text}>{slide.text}</h2>
              </div>
            </Slide>
          ) : (
            <Slide key={i} className={styles.slide} index={i}>
              <Image
                className={styles.image}
                hasMasterSpinner
                src={slide.background}
                alt=""
              ></Image>
            </Slide>
          ),
        )}
      </Slider>
      {slides.length > 1 && (
        <>
          {/* <ButtonBack className={styles.button + ' ' + styles.buttonBack}>
            <i className={styles.buttonIcon + ' fas fa-chevron-left'}></i>
          </ButtonBack>
          <ButtonNext className={styles.button + ' ' + styles.buttonNext}>
            <i className={styles.buttonIcon + ' fas fa-chevron-right'}></i>
          </ButtonNext> */}
          <DotGroup className={styles.dotGroup}></DotGroup>
        </>
      )}
    </CarouselProvider>
  );
};

export default SlideCarousel;
