import React from 'react';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';

interface AboutUsProps {
  watermark: string;
  sectionTitle: React.ReactNode;
  textIntro: React.ReactNode;
  missionTitle: string;
  missionText: React.ReactNode;
  valuesTitle: string;
  values: string;
}

const AboutUs = ({
  watermark,
  sectionTitle,
  textIntro,
  missionTitle,
  missionText,
  valuesTitle,
  values,
}: AboutUsProps) => {
  return (
    <>
      <section id="aboutUs" className="section">
        <div id="aboutUs-anchor" className="anchor "></div>
        <div className="container">
          <header className="section-header">
            <div className="watermarkContainer">
              <strong className="fade-title">{watermark}</strong>
            </div>
            <h2 className="section-title">{sectionTitle}</h2>
          </header>
          <div className="section-content wow fadeIn text-intro-container">{textIntro}</div>
        </div>
      </section>
      <section className="mission-and-values section">
        <div className="container ">
          <div className="mission-and-values-container">
            <div className="missionContainer">
              <h3 className="subtitle">{missionTitle}</h3>
              <p>{missionText}</p>
            </div>
            <div className="valuesContainer">
              <h3 className="subtitle">{valuesTitle}</h3>
              <p>{values}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
