import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

interface MenuTopicProps {
  anchor: string;
  name: string;
  className?: string;
}

export default function MenuTopic({ anchor, name, className }: MenuTopicProps) {
  return (
    <li>
      <AnchorLink href={'#' + anchor + '-anchor'}>
        <a
          className={className}
          // @ts-ignore
          onClick={() => $('.navbar-collapse').collapse('hide')}
        >
          {name}
        </a>
      </AnchorLink>
    </li>
  );
}
