import React from 'react';
import { SanityImage } from '../types';
import CardCarousel from '../components/CardCarousel';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';

interface TrackRecordProps {
  lang: string;
  watermark: string;
  sectionTitle: React.ReactNode;
  introText: React.ReactNode;
  cardsCarrousel: Array<{
    _key: string;
    image: {
      asset: {
        url: string;
      };
    };
    projectName: string;
    projectDate?: string;
    description: string;
  }>;
  investment: string;
  buildings: string;
  irrPercentage: string;
}

const TrackRecord = ({
  lang,
  watermark,
  sectionTitle,
  introText,
  cardsCarrousel,
  investment,
  buildings,
  irrPercentage,
}: TrackRecordProps) => {
  return (
    <>
      <section id="trackRecord" className="section">
        <div id="trackRecord-anchor" className="anchor"></div>
        <div className="container">
          <header className="section-header">
            <div className="watermarkContainer">
              <strong className="fade-title">{watermark}</strong>
            </div>
            <h2 className="section-title">{sectionTitle}</h2>
          </header>
          <div className="section-content wow fadeIn text-intro-container">{introText}</div>
        </div>
        <CardCarousel
          cards={cardsCarrousel.map(card => ({
            imageSrc: card.image.asset.url,
            title: card.projectDate ? card.projectName + ', ' + card.projectDate : card.projectName,
            text: card.description,
          }))}
        ></CardCarousel>
        <div id="projectInfoBar" className="project-info">
          <div className="container ">
            <div className="project-info-container">
              <span className="info">{investment}</span>
              <span className="info">{buildings}</span>
              <span className="info">{irrPercentage}</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrackRecord;
